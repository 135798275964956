<template>
  <div class="apply">
    <div class="news-container">
      <div v-show="titleActive == 1" class="news-container-item">
        <div
          class="item-li"
          v-for="(item, key) in detailData[0]"
          :key="key"
          @click="toDetail(item.newsID)"
        >
          <div class="item-li-box">
            <div class="item-li-box-img droductNewsBox">
              <img :src="item.img" alt="" />
            </div>
            <div class="item-li-box-detail">
              <h3 class="yichu1" v-html="item.title">
                维超智能诚邀您参加——2021年第四届深圳(国际)智慧显示系统产业应用博览会
              </h3>
              <p class="dot3" v-html="item.detail">
                维超智能将凭借精心打磨的大利器
                幻影3D点阵偏光技术，神经网络人眼追踪算法 3D
                LED电影屏，裸眼3D近视防控平板 还原人类更真实、更生动的现实视界
              </p>
              <span class="item-li-box-detail-time" v-html="item.time"
                >2021-08-31</span
              >
            </div>
          </div>
        </div>
        <el-pagination background layout="prev, pager, next" :total="2">
        </el-pagination>
      </div>
      <div v-show="titleActive == 2" class="industry">
        <div class="industry-li">
          <div class="industry-li-img droductNewsBox">
            <img src="@/assets/img/news/20201019140707601.jpg" alt="" />
          </div>
          <div class="txt">
            <div class="time">
              <span>28</span>
              2020-12
            </div>
            <h2>
              <router-link
                class="dot yichu1"
                to="/"
                title="Glasses-free 3D, 5G + 8K, virtual reality, unmanned aerial vehicles ,these black technologies in the Spring Festival Evening of 2020."
              >
                Glasses-free 3D, 5G + 8K, virtual reality, unmanned aerial
                vehicles ,these black technologies in the Spring Festival
                Evening of 2020.</router-link
              >
            </h2>
            <p class="dot3">
              Every Spring Festival Gala on New Year's Eve is an appetizing
              "audiovisual dinner" on the audience's dinner table. In recent
              years, the "tech taste" of this big meal has become stronger and
              stronger. This year's Spring Festival Gala stage was the first to
              create a three-layer three-dimensional dance beauty, while using
              flying screen technology to create a 360-degree surround
              landscape, so that the audience can have the ultimate experience
              of "naked eye 3D" in front of the screen.
            </p>
            <router-link
              :to="{
                path: '/news/detail',
                query: { id: this.titleActive, newsId: 11 },
              }"
              class="more"
              >More</router-link
            >
          </div>
        </div>
        <div class="industry-list">
          <el-row class="row-bg">
            <!-- <el-col :xs="24" :sm="8">
              <div class="grid-content bg-purple">
                <div class="industry-list-img"></div>
                <div class="txt">
                  <h2>
                    <router-link class="yichu1" to="/">
                      捷豹路虎开发裸眼3D车载显示系统 将有利于自动驾驶
                    </router-link>
                  </h2>
                  <p class="yichu">
                    尽管近年来3D内容已经不再流行，许多制造商已经放弃了3D电视，但是，捷豹路虎正在开发一种信息娱乐系统，该系统利用3D图像，将它们投射到驾驶员和乘客面前。
                    这款3D抬头显示器和信息娱乐系统的主要功能是将实时安全信息投射到驾驶员的视野中，其中包括导航提示，任何潜在障碍和车道偏离警告等等。
                  </p>
                  <div class="time intime">
                    <span>30</span>
                    2022-2
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8">
              <div class="grid-content bg-purple">
                <div class="industry-list-img"></div>
                <div class="txt">
                  <h2>
                    <router-link class="yichu1" to="/">
                      捷豹路虎开发裸眼3D车载显示系统 将有利于自动驾驶
                    </router-link>
                  </h2>
                  <p class="yichu">
                    尽管近年来3D内容已经不再流行，许多制造商已经放弃了3D电视，但是，捷豹路虎正在开发一种信息娱乐系统，该系统利用3D图像，将它们投射到驾驶员和乘客面前。
                    这款3D抬头显示器和信息娱乐系统的主要功能是将实时安全信息投射到驾驶员的视野中，其中包括导航提示，任何潜在障碍和车道偏离警告等等。
                  </p>
                  <div class="time intime">
                    <span>30</span>
                    2022-2
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8">
              <div class="grid-content bg-purple">
                <div class="industry-list-img"></div>
                <div class="txt">
                  <h2>
                    <router-link class="yichu1" to="/">
                      捷豹路虎开发裸眼3D车载显示系统 将有利于自动驾驶
                    </router-link>
                  </h2>
                  <p class="yichu">
                    尽管近年来3D内容已经不再流行，许多制造商已经放弃了3D电视，但是，捷豹路虎正在开发一种信息娱乐系统，该系统利用3D图像，将它们投射到驾驶员和乘客面前。
                    这款3D抬头显示器和信息娱乐系统的主要功能是将实时安全信息投射到驾驶员的视野中，其中包括导航提示，任何潜在障碍和车道偏离警告等等。
                  </p>
                  <div class="time intime">
                    <span>30</span>
                    2022-2
                  </div>
                </div>
              </div>
            </el-col> -->
            <el-col
              :xs="24"
              :sm="8"
              v-for="(item, key) in detailData2[0]"
              :key="key"
            >
              <div class="grid-content bg-purple">
                <div
                  class="industry-list-img"
                  :style="{ backgroundImage: 'url(' + item.img + ')' }"
                ></div>
                <div class="txt">
                  <h2>
                    <router-link
                      class="yichu1"
                      :to="{
                        path: '/news/detail',
                        query: { id: titleActive, newsId: item.newsID },
                      }"
                      v-html="item.title"
                    >
                      捷豹路虎开发裸眼3D车载显示系统 将有利于自动驾驶
                    </router-link>
                  </h2>
                  <p class="yichu" v-html="item.detail">
                    尽管近年来3D内容已经不再流行，许多制造商已经放弃了3D电视，但是，捷豹路虎正在开发一种信息娱乐系统，该系统利用3D图像，将它们投射到驾驶员和乘客面前。
                    这款3D抬头显示器和信息娱乐系统的主要功能是将实时安全信息投射到驾驶员的视野中，其中包括导航提示，任何潜在障碍和车道偏离警告等等。
                  </p>
                  <div class="time intime">
                    <span>28</span>
                    2020-3
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-pagination background layout="prev, pager, next" :total="2">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news",
  data() {
    return {
      titleActive: this.$route.query.id || 1,
      detailData: [
        [
          {
            newsID: "11",
            title:
              "Can 5G bring about the rapid development of glasses-free 3D technology?",
            img: require("../../assets/img/news/5g.jpeg"),
            detail:
              "In the past, we read books, watch TV, and watch movies on a two-dimensional level. To experience the 3D effect, only wear special 3D glasses, but not with glasses-free 3D. We can experience three-dimensional without using glasses visual feast.",
            time: "2020-4-27",
          },
          {
            newsID: "12",
            title: "Analysis of the pain points of the naked eye 3D market.",
            img: require("../../assets/img/news/news_center_img3.jpg"),
            detail:
              "In the current glasses-free 3D industry, glasses-free 3D display terminals are mainly based on external devices, and major enterprises still remain at the level of fighting for their own traditional hardware display terminals, which also leads to the dispersion of 3D data. Due to the non-interoperability of various platforms, the development of the high-quality 3D content industry is largely limited.",
            time: "2020-4-27",
          },
          {
            newsID: "13",
            title: "3D technology market development status and future trends.",
            img: require("../../assets/img/news/5g.jpeg"),
            detail:
              'In recent years, global 3D technologies, including 3D printing, 3D cameras, 3D games, 3D displays, and 3D software, have developed rapidly, and the market scale has continued to expand. According to the data of "Global 3D Technology Market Development Status and Future Trends" released by Markets & Markets, the global 3D technology market size was USD 127.84 billion in 2016, and it is expected to reach USD 245.8 billion by 2022, with a compound annual growth rate of 16.17%. In the upstream of the entire 3D industry, glasses-free 3D  terminal products, 3D scanning, 3D modeling and surveying, etc., have good development prospects.',
            time: "2020-4-27",
          },
          {
            newsID: "14",
            title:
              "The Glasses-Free 3D wind is blowing again, the mobile phone industry is ready to go?",
            img: require("../../assets/img/news/20191009151351728.jpg"),
            detail:
              "The Glasses-Free 3D technology is a new type of image display technology that allows viewers to obtain a highly realistic visual experience without wearing 3D glasses.",
            time: "2020-4-27",
          },
        ],
      ],
      detailData2: [
        [
          {
            newsID: "12",
            title:
              "Glasses-free 3D, 5G + 8K, virtual reality, unmanned aerial vehicles ,these black technologies in the Spring Festival Evening of 2020.",
            img: require("../../assets/img/news/news_center_img3.jpg"),
            detail:
              "Every Spring Festival Gala on New Year's Eve is an appetizing 'audiovisual dinner' on the audience's dinner table. In recent years, the 'tech taste' of this big meal has become stronger and stronger. This year's Spring Festival Gala stage was the first to create a three-layer three-dimensional dance beauty, while using flying screen technology to create a 360-degree surround landscape, so that the audience can have the ultimate experience of 'naked eye 3D' in front of the screen.",
            time: "2020-4-28",
          },
          {
            newsID: "13",
            title: "Why not use glasses-free 3D projection in movie theaters?",
            img: require("../../assets/img/news/movie.jpeg"),
            detail:
              "Some people will find that the glasses-free 3D technology has clearly appeared, but the actual application is very few. Usually, the  glasses-free 3D technology is not seen at all. Even in some large exhibition halls, such as movie theaters, the 3D projection technology is not widely used. . Why not use glasses-free 3D projection in movie theaters?",
            time: "2020-8-16",
          },
          {
            newsID: "14",
            title:
              "China's first naked-eye 3D map-the original map can be seen like this.",
            img: require("../../assets/img/news/3Dearth.jpeg"),
            detail:
              "Harbin Map Publishing House publishes 'The Three-Dimensional Vision 3D China-The People's Republic of China 3D Atlas', which uses 3D visual effects in the map. The atlas is the first naked-eye 3D Chinese atlas in China, which is a collection of high-tech innovations in the current field of cartography. A whole new way.",
            time: "2020-8-16",
          },
          {
            newsID: "15",
            title: "Glasses-free3D display by car is here.",
            img: require("../../assets/img/news/car3D.jpeg"),
            detail:
              "Recently, Bosch officially launched a new 3D display. This product uses a passive 3D technology display that can generate very realistic 3D effects compared to traditional displays, making it easier and faster for drivers to grab the screen Display information.",
            time: "2020-4-28",
          },
        ],
      ],
    };
  },
  methods: {
    toDetail(id) {
      this.$router.push({
        path: "/news/detail",
        query: { id: this.titleActive, newsId: id },
      });
    },
  },
  watch: {
    $route(to, from) {
      //监听路由，当路由改变时，改变选项
      this.titleActive = to.query.id;
    },
  },
};
</script>

<style lang="less" scoped>
.apply {
  padding-top: 3%;
  .news-container {
    text-align: center;
    margin: 0 auto;
    max-width: 1400px;
    .news-container-item {
      // margin-bottom: 48px;
      // padding: 10px;
      // border: 1px solid #ebebeb;
      // margin-bottom: 3%;
      .item-li {
        padding: 10px;
        border: 1px solid #ebebeb;
        margin-bottom: 3%;
        &:hover {
          border-color: #006cba;
          box-shadow: 0 5px 5px #eee;
          padding: 10px;
          // border: 1px solid #ebebeb;
          margin-bottom: 3%;
          .item-li-box {
            &::after {
              background-position: left bottom;
            }
          }
        }

        .item-li-box {
          &::after {
            position: absolute;
            content: "";
            width: 33px;
            height: 33px;
            background: url("~@/assets/img/news/PicList001200-more.png")
              no-repeat left top;
            right: 5%;
            top: 50%;
            margin-top: -16px;
          }
          display: block;
          position: relative;
          padding-left: 30%;
          padding-right: 15%;
          height: 220px;
          .item-li-box-img {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 27%;
          }
        }
        .item-li-box-detail {
          text-align: left;
          &::before {
            width: 1px;
            height: 70%;
            top: 15%;
            background-color: #ebebeb;
            right: 0;
            position: absolute;
            content: "";
          }
          &::after {
            position: absolute;
            content: "";
            width: 7%;
            height: 1px;
            background-color: #006cba;
            bottom: 10%;
            left: -9%;
            z-index: 2;
          }
          position: relative;
          padding-right: 5%;
          padding-bottom: 10%;
          cursor: pointer;
          h3 {
            margin: 0;
            font-size: 18px;
            padding: 3% 0 0 0;
            font-weight: normal;
            color: #333;
          }
          p {
            margin: 3% 0 0 0;
            color: #666;
            line-height: 28px;
            height: 80px;
            overflow: hidden;
            font-size: 14px;
          }
          .item-li-box-detail-time {
            position: absolute;
            left: 0;
            bottom: 5%;
            color: #666;
          }
        }
      }
    }
    .industry {
      .industry-li {
        background-color: #fafafa;
        margin-bottom: 2%;
        position: relative;
        padding-left: 42%;
        margin-bottom: 3%;
        height: 403px;
        .industry-li-img {
          width: 42%;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
        }
      }

      .txt {
        color: #666;
        padding: 5% 5% 3% 5%;
        text-align: left;
        p {
          font-size: 15px;
          line-height: 24px;
          height: 72px;
          margin: 5% 0 10% 0;
        }
        .time {
          font-weight: bold;
          float: right;
          text-align: center;
          font-size: 13px;
          color: #0068b7;
          span {
            display: block;
            font-size: 44px;
          }
        }
        h2 {
          clear: both;
          margin: 0;
          font-size: 26px;
          font-weight: normal;
          &::after {
            display: block;
            content: "";
            width: 35px;
            height: 2px;
            background: #0068b7;
            margin: 3% 0 0 0;
            box-sizing: border-box;
          }
          a:hover {
            color: #0068b7;
            text-decoration: none;
          }
        }
        a {
          color: #333;
          text-decoration: none;
          display: block;
        }
        .more {
          display: inline-block;
          position: relative;
          color: #6b6b6b;
          font-size: 12px;
          padding-right: 26px;
          padding-bottom: 5px;
          border-bottom: 1px solid #0068b7;
          &::after {
            position: absolute;
            content: "";
            border: 6px solid transparent;
            bottom: -6px;
            right: -6px;
            border-left-color: #0068b7;
          }
          &:hover {
            color: #0068b7;
          }
        }
      }
    }
    .industry-list {
      margin-top: 20px;
      min-height: 598px;
      .grid-content {
        position: relative;
        padding-right: 25px;
        margin-bottom: 25px;
        .industry-list-img {
          padding-block: 40%;
          background: url("~@/assets/img/news/20220215170324889.jpg");
          background-position: center center;
          // background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
      .txt {
        background: #f8f8f8;
        text-align: center;
        padding: 5% 3%;
        margin: 0;
        // &::after {
        //   display: block;
        //   content: "";
        //   width: 30px;
        //   height: 2px;
        //   margin: 2% auto 4%;
        //   background: #0068b7;
        // }
        h2 {
          font-size: 18px;
        }
        p {
          font-size: 14px;
          line-height: 24px;
          padding: 0 5%;
          overflow: hidden;
          height: 48px;
          margin: 5% 0;
        }
        .intime {
          color: #fff;
          position: absolute;
          left: 0;
          top: 0;
          width: 97px;

          padding: 2% 0;
          background: #0068b7;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .apply {
    .news-container {
      .news-container-item {
        .item-li {
          .item-li-box {
            height: 180px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .apply {
    .news-container {
      .industry-list {
        padding: 0 15px;
        .grid-content {
          padding-right: 0;
        }
      }
      .industry {
        .industry-li {
          padding-left: 0;
          height: 0;
        }
        .txt {
          .time {
            float: none;
          }
        }
      }
    }
  }
}
@media (max-width: 460px) {
  .apply {
    .news-container {
      .news-container-item {
        .item-li {
          .item-li-box {
            height: 148px;
          }
          .item-li-box {
            padding-right: 12%;
            &::after {
              right: 0;
            }
          }
          .item-li-box-detail {
            p {
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
            .item-li-box-detail-time {
              bottom: -5%;
            }
            &::after {
              bottom: 2%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 460px) {
  .apply {
    .news-container {
      .news-container-item {
        .item-li {
          .item-li-box {
            height: 137px;
          }
        }
      }
    }
  }
}
</style>>
